.skills-container {
  height: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  padding: 40px;
  padding-bottom: 80px;
  width: 100%;
}

.skills-header {
  justify-self: flex-start;
  color: #1f2227;
  font-size: 45px;
  font-weight: 400;
  height: 180px;
}

.skills-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background: -webkit-linear-gradient(top left, #1f2227 0%, #437aed 100%);
  background: linear-gradient(to bottom right, #1f2227 0%, #437aed 100%);
  clip-path: polygon(0 45%, 100% 0, 100% 100%, 0 100%); /* Slanted top */
  z-index: -1;
}

.skills-grid {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(5, 1fr);
  gap: 50px;
  max-width: 700px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999999999px;
  width: 75px;
  height: 75px;
  box-shadow: 0px 1px 18px 5px rgba(4, 55, 174, 0.53);
  transform: translatey(0px);
  animation: float 7s ease-in-out infinite;
  background: white;
  border: 1px solid white;
  margin: auto;
}

.icon-container svg,
.icon-container img {
  object-fit: contain;
  max-width: 50px;
  max-height: 100%;
  z-index: 99999999999;
}

@keyframes float {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .skills-grid {
    grid-template-columns: repeat(3, 1fr);
    max-width: 400px;
  }
}

@media (max-width: 400px) {
  .skills-header {
    font-size: 40px;
  }
  .skills-container {
    /* height: 70vh; */
    padding: 40px 20px;
  }

  .skills-container::before {
    height: 90vh;
  }

  .skills-grid {
    gap: 40px;
  }

  .icon-container {
    width: 50px;
    height: 50px;
  }

  .icon-container svg,
  .icon-container img {
    max-width: 30px;
  }
}
