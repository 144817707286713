@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: normal;
}

h1 {
  font-size: 24px;
  font-weight: 300;
}

.btn {
  font-size: 18px;
  width: 140px;
  height: 50px;
  font-weight: 100;
  border-radius: 40px;
  padding: 3px;
  outline: none;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  overflow: hidden;
  background-image: linear-gradient(30deg, transparent 50%, #3e72e2 50%);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 0%;
  transition: background 600ms ease-in-out;
}

.btn:hover {
  background-position: 100%;
  color: #fff;
  cursor: pointer;
}

.btn-dark {
  font-size: 18px;
  width: 140px;
  height: 50px;
  padding: 3px;
  outline: none;
  border: 3px solid #fff;
  background: transparent;
  color: #fff;
  overflow: hidden;
  background-image: linear-gradient(30deg, #1f2227 50%, #3e72e2 50%);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 0%;
  transition: background 600ms ease-in-out;
}

.btn-dark:hover {
  background-position: 100%;
  color: #fff;
  cursor: pointer;
}

.btns {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 5px 5px;
}

/* MOBILE */
@media (max-width: 812px) {
  .btn {
    font-size: 12px;
    width: 100px;
    height: 35px;
  }
}

.navbar {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  position: fixed;
  top: 0;
  text-align: center;
  transition: top 0.6s;
  z-index: 50;
  background: #2e5195;
  height: 100px;
}

.nav-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px 40px;
  height: 100%;
}

.logo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.nav-links {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 20px;
  gap: 20px;
  list-style: none;
  height: 100%;
}

.nav-links li a {
  font-weight: 500;
  text-decoration: none;
  font-size: 22px;
  padding-bottom: 12px;
}

.dark-links {
  color: #1f2227;
}
.light-links {
  color: #fff;
}

.nav-links li a:hover {
  border-bottom: 4px solid #437aed;
}

/* MOBILE */
@media (max-width: 812px) {
  .navbar {
    height: 80px;
  }
  .logo {
    width: 50px;
    height: 50px;
  }
  .nav-container {
    padding: 10px 20px;
  }

  .nav-links {
    grid-gap: 10px;
    gap: 10px;
  }

  .nav-links li a {
    font-size: 20px;
    padding-bottom: 8px;
  }
}
/* @media (max-width: 499px) {
  .logo {
    width: 30px;
    height: 30px;
  }
  .nav-container {
  }

  .nav-links {
    gap: 10px;
  }

  .nav-links li a {
    font-size: 16px;
  }
} */

.ReactModal__Overlay--after-open {
  z-index: 500;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.7) !important;
}

/* SHOWCASE */
.showcase {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow-x: hidden;
  background: #1f2227;
  background: linear-gradient(to bottom right, #1f2227 0%, #437aed 100%);
}

.showcase-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-decoration: none;
  font-size: 20px;
  height: 45px;
  width: 130px;
}

.showcase .showcase-content {
  position: absolute;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 5px;
  padding: 8px;
}

.showcase .showcase-content h1 {
  color: #fff;
  font-size: 70px;
  margin: 5px 0;
  font-weight: 300;
}

.showcase .showcase-content h1 span {
  color: #437bf5;
}

.showcase .showcase-content h5 {
  color: #fff;
  font-size: 26px;
  font-weight: 300;
  margin: 8px 0 20px 0;
}

/* MOBILE */
@media (max-width: 812px) {
  .showcase .showcase-content h1 {
    font-size: 40px;
    margin: 5px 0;
  }
  .showcase .showcase-content h5 {
    color: #fff;
    font-size: 20px;
    margin: 8px 0 20px 0;
  }

  .ReactModal__Content,
  .iframe {
    max-height: 430px !important;
  }

  .ReactModal__Content {
    inset: 20px !important;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  .showcase .showcase-content h5 {
    font-size: 18px;
  }
}

/* FLOATING DOTS */
.showcase .dot {
  width: 5px;
  height: 5px;
  background: #437aed;
  border-radius: 50%;
}

.showcase .dotWrapper {
  position: absolute;
}

.showcase .dotWrapper-1 {
  top: 55%;
  left: 80%;
  -webkit-animation: flying 38s ease-in-out -4.9s infinite alternate;
  animation: flying 38s ease-in-out -4.9s infinite alternate;
}
.showcase .dotWrapper-2 {
  top: 17%;
  left: 49%;
  -webkit-animation: flying 35s ease-in-out -2.1s infinite alternate;
  animation: flying 35s ease-in-out -2.1s infinite alternate;
}
.showcase .dotWrapper-3 {
  top: 80%;
  left: 83%;
  -webkit-animation: flying 48s ease-in-out -4s infinite alternate;
  animation: flying 48s ease-in-out -4s infinite alternate;
}
.showcase .dotWrapper-4 {
  top: 20%;
  left: 50%;
  -webkit-animation: flying 65s ease-in-out -8.9s infinite alternate;
  animation: flying 65s ease-in-out -8.9s infinite alternate;
}
.showcase .dotWrapper-5 {
  top: 58%;
  left: 2%;
  -webkit-animation: flying 40s ease-in-out -8.1s infinite alternate;
  animation: flying 40s ease-in-out -8.1s infinite alternate;
}
.showcase .dotWrapper-6 {
  top: 59%;
  left: 56%;
  -webkit-animation: flying 27s ease-in-out -4s infinite alternate;
  animation: flying 27s ease-in-out -4s infinite alternate;
}
.showcase .dotWrapper-7 {
  top: 60%;
  left: 78%;
  -webkit-animation: flying 36s ease-in-out -5.5s infinite alternate;
  animation: flying 36s ease-in-out -5.5s infinite alternate;
}
.showcase .dotWrapper-8 {
  top: 71%;
  left: 59%;
  -webkit-animation: flying 51s ease-in-out -3.8s infinite alternate;
  animation: flying 51s ease-in-out -3.8s infinite alternate;
}
.showcase .dotWrapper-9 {
  top: 65%;
  left: 38%;
  -webkit-animation: flying 55s ease-in-out -4.2s infinite alternate;
  animation: flying 55s ease-in-out -4.2s infinite alternate;
}
.showcase .dotWrapper-10 {
  top: 80%;
  left: 57%;
  -webkit-animation: flying 39s ease-in-out -1.1s infinite alternate;
  animation: flying 39s ease-in-out -1.1s infinite alternate;
}
.showcase .dotWrapper-11 {
  top: 64%;
  left: 66%;
  -webkit-animation: flying 21s ease-in-out -1.4s infinite alternate;
  animation: flying 21s ease-in-out -1.4s infinite alternate;
}
.showcase .dotWrapper-12 {
  top: 71%;
  left: 17%;
  -webkit-animation: flying 42s ease-in-out -3.1s infinite alternate;
  animation: flying 42s ease-in-out -3.1s infinite alternate;
}
.showcase .dotWrapper-13 {
  top: 72%;
  left: 77%;
  -webkit-animation: flying 44s ease-in-out -1.2s infinite alternate;
  animation: flying 44s ease-in-out -1.2s infinite alternate;
}
.showcase .dotWrapper-14 {
  top: 37%;
  left: 31%;
  -webkit-animation: flying 70s ease-in-out -6.7s infinite alternate;
  animation: flying 70s ease-in-out -6.7s infinite alternate;
}
.showcase .dotWrapper-15 {
  top: 57%;
  left: 17%;
  -webkit-animation: flying 62s ease-in-out -6.8s infinite alternate;
  animation: flying 62s ease-in-out -6.8s infinite alternate;
}
.showcase .dotWrapper-16 {
  top: 43%;
  left: 53%;
  -webkit-animation: flying 57s ease-in-out -6.6s infinite alternate;
  animation: flying 57s ease-in-out -6.6s infinite alternate;
}
.showcase .dotWrapper-17 {
  top: 34%;
  left: 45%;
  -webkit-animation: flying 28s ease-in-out -3.9s infinite alternate;
  animation: flying 28s ease-in-out -3.9s infinite alternate;
}
.showcase .dotWrapper-18 {
  top: 15%;
  left: 80%;
  -webkit-animation: flying 38s ease-in-out -7.5s infinite alternate;
  animation: flying 38s ease-in-out -7.5s infinite alternate;
}
.showcase .dotWrapper-19 {
  top: 60%;
  left: 45%;
  -webkit-animation: flying 38s ease-in-out -9.9s infinite alternate;
  animation: flying 38s ease-in-out -9.9s infinite alternate;
}
.showcase .dotWrapper-20 {
  top: 30%;
  left: 20%;
  -webkit-animation: flying 31s ease-in-out -7s infinite alternate;
  animation: flying 31s ease-in-out -7s infinite alternate;
}

@-webkit-keyframes rotating {
  0% {
    opacity: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25%,
  75% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotating {
  0% {
    opacity: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25%,
  75% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes flying {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  10% {
    -webkit-transform: translate(20px, 50px);
            transform: translate(20px, 50px);
  }
  20% {
    -webkit-transform: translate(-30px, 10px);
            transform: translate(-30px, 10px);
  }
  30% {
    -webkit-transform: translate(10px, 60px);
            transform: translate(10px, 60px);
  }
  40% {
    -webkit-transform: translate(50px, 0px);
            transform: translate(50px, 0px);
  }
  50% {
    -webkit-transform: translate(-10px, -40px);
            transform: translate(-10px, -40px);
  }
  60% {
    -webkit-transform: translate(-40px, 20px);
            transform: translate(-40px, 20px);
  }
  70% {
    -webkit-transform: translate(30px, -30px);
            transform: translate(30px, -30px);
  }
  80% {
    -webkit-transform: translate(0px, -60px);
            transform: translate(0px, -60px);
  }
  90% {
    -webkit-transform: translate(40px, 10px);
            transform: translate(40px, 10px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@keyframes flying {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  10% {
    -webkit-transform: translate(20px, 50px);
            transform: translate(20px, 50px);
  }
  20% {
    -webkit-transform: translate(-30px, 10px);
            transform: translate(-30px, 10px);
  }
  30% {
    -webkit-transform: translate(10px, 60px);
            transform: translate(10px, 60px);
  }
  40% {
    -webkit-transform: translate(50px, 0px);
            transform: translate(50px, 0px);
  }
  50% {
    -webkit-transform: translate(-10px, -40px);
            transform: translate(-10px, -40px);
  }
  60% {
    -webkit-transform: translate(-40px, 20px);
            transform: translate(-40px, 20px);
  }
  70% {
    -webkit-transform: translate(30px, -30px);
            transform: translate(30px, -30px);
  }
  80% {
    -webkit-transform: translate(0px, -60px);
            transform: translate(0px, -60px);
  }
  90% {
    -webkit-transform: translate(40px, 10px);
            transform: translate(40px, 10px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

.container {
  height: 90vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 60px;
  gap: 60px;
  padding: 40px;
  width: 100%;
}

.bold {
  font-weight: 400;
}

.profile-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  grid-gap: 15px;
  gap: 15px;
  width: 100%;
  z-index: 10;
  max-width: 450px;
  background: transparent;
  color: #1f2227;
}

.about-text {
  text-align: left;
}

.about-content p {
  font-size: 20px;
}

h1 {
  font-size: 30px;
}

.profile-header {
  color: #1f2227;
  font-size: 45px;
  font-weight: 400;
}

.bio-text {
  font-size: 22px;
  font-weight: 350;
  color: #1f2227;
  /* color: #333b47; */
}

.about-content {
  width: 100%;
  max-width: 550px;
}

.bio-image {
  max-width: 100%;
  min-width: 220px;
}

/* MOBILE */
@media (max-width: 1000px) {
  .container {
    height: 100vh;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    grid-gap: 30px;
    gap: 30px;
  }
  .profile-content {
    max-width: 510px;
  }
}

@media (max-width: 499px) {
  .profile-header {
    font-size: 35px;
  }
  .container {
    padding: 20px;
    height: 85vh;
  }
}

@media (max-width: 399px) {
  .profile-header {
    font-size: 35px;
  }
  .container {
    padding: 20px;
  }
}

@media (max-width: 399px) and (max-height: 600px) {
  .container {
    height: 110vh;
  }
}

.button {
  font-size: 18px;
  width: 140px;
  height: 50px;
  font-weight: 100;
  border-radius: 40px;
  color: #fff;
  padding: 4px 8px;
  border: none;
  background: #1f2227;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  will-change: transform;
  transition: box-shadow 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, box-shadow 500ms ease-in-out;
  transition: transform 500ms ease-in-out, box-shadow 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

.button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  background-position: 100%;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 1px 12px 5px #ccc;
}

@media (max-width: 399px) {
  .button {
    font-size: 14px;
    width: 100px;
    height: 45px;
  }
}

.email-logo {
  width: 30px;
}

.email-text-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  /* padding: 10px; */
  grid-gap: 10px;
  gap: 10px;
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

.email-text-container .button-text {
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  height: 100%;
  width: 100%;
}

.email-text-container .fa-envelope {
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.social {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: #1f2227;
  width: 100%;
  height: 20vh;
}

.social-icons {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  list-style: none;
  padding: 0;
}

.social-links li a {
  border: 3px solid #fff;
  width: 200px;
  height: 200px;
}

.social-icons li {
  list-style: none;
  margin: 0 24px;
}

.social-icons li a {
  color: #fff;
  font-size: 60px;
}

.social-icons li a i:hover {
  background: #437aed;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* MOBILE */
@media (max-width: 812px) {
  .social-icons li {
    margin: 0 12px;
  }

  .social-icons li a {
    color: #fff;
    font-size: 40px;
  }
}

.grid-container {
  display: grid;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  margin: 0 auto;
}

.gallery-item {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
  width: 500px;
  height: 300px;
}

.gallery-image {
  width: 100%;
  height: 100%;
  transition: -webkit-transform 400ms ease-out;
  transition: transform 400ms ease-out;
  transition: transform 400ms ease-out, -webkit-transform 400ms ease-out;
  z-index: 50;
}
.gallery-item .btn-dark {
  z-index: 100;
  position: absolute;
  opacity: 0;
  transition: 0.8s ease;
}

.gallery-item:hover .gallery-image {
  opacity: 0.4;
  transition: 0.8s ease;
}

.gallery-item:hover .btn-dark {
  opacity: 1;
}

/* MODAL */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.modal-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0;
}
.modal-content h1 {
  margin-bottom: 8px;
}

.modal-content p {
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 20px;
}

.modal-content h1,
.modal-content p {
  text-align: center;
}

.modal-btns {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.modal-btns a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  height: 45px;
  width: 130px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}
.modal-btns button {
  background: transparent;
  outline: none;
  border: none;
  font-size: 20px;
}

.modal-btns button:hover {
  color: #437aed;
  cursor: pointer;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* SMALLER DESTOP */
@media (max-width: 1100px) and (max-height: 900px) {
  .modal {
    max-height: 80%;
  }

  .gallery-item {
    max-width: 280px;
    max-height: 180px;
  }
}
@media (min-width: 1000px) and (max-width: 1400px) {
  .modal-btns a {
    height: 35px;
    width: 120px;
    font-size: 18px;
    z-index: 500;
  }
  .modal-btns button {
    z-index: 500;
  }
  .gallery-item {
    width: 320px;
    height: 200px;
  }
  .modal {
    width: 85%;
    height: 90%;
  }
  .modal-content h1 {
    font-size: 24px;
  }
  .modal-content p {
    font-size: 16px;
  }
}

/* MOBILE */
@media (max-width: 1000px) {
  .modal-btns a {
    font-size: 15px;
    width: 120px;
  }
  .grid-container {
    height: 200vh;
  }
  .gallery {
    grid-template-columns: 1fr;
  }
  .gallery-item {
    width: 300px;
    height: 200px;
  }
  h1 {
    font-weight: 400;
    font-size: 30px;
  }
  .modal {
    width: 75%;
    height: 50%;
  }
}

/* SHORT PHONES */
@media (max-width: 375px) and (max-height: 667px) {
  .grid-container {
    height: 225vh;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  .grid-container {
    height: 250vh;
  }

  .gallery-item {
    width: 275px;
    height: 175px;
  }
}

.fade-in {
  opacity: 0;
  -webkit-transform: translateY(40vh);
          transform: translateY(40vh);
  visibility: hidden;
  transition: opacity 1400ms ease-out, visibility 1400ms ease-out, -webkit-transform 600ms ease-out;
  transition: opacity 1400ms ease-out, transform 600ms ease-out,
    visibility 1400ms ease-out;
  transition: opacity 1400ms ease-out, transform 600ms ease-out,
    visibility 1400ms ease-out, -webkit-transform 600ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in.visible {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
  visibility: visible;
}

.contact-container {
  height: 60vh;
  width: 100%;
  max-width: 1200px;
  padding: 40px;
  margin: auto;
}
.contact-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100%;
  height: 100%;
  margin: auto;
}
.contact-headers {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.contact-header {
  font-weight: 600;
  color: #1f2227;
  font-size: 45px;
}

.secondary-header {
  color: #437bf5;
}
.linkedin-logo {
  width: 100%;
  height: 40px;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.buttons-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 15px;
  gap: 15px;
}

/* MOBILE */
@media (max-width: 599px) {
  .contact-header {
    font-size: 35px;
  }
}

@media (max-width: 399px) {
  .contact-container {
    height: 80vh;
    padding: 20px;
  }
  .linkedin-logo {
    height: 35px;
  }
  .contact-header {
    font-size: 28px;
  }
  .buttons-container {
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .buttons-container .button {
    width: 100%;
  }

  .contact-container {
    padding: 20px;
  }
}

@media (max-width: 900px) {
  .contact-content {
    max-width: 500px;
    grid-gap: 30px;
    gap: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
  }
}

footer {
  background: #1f2227;
  height: 10vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #fff;
  font-size: 16px;
  padding: 30px;
  font-weight: bold;
}

.skills-container {
  height: 100%;
  height: 80vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
  padding: 40px;
  padding-bottom: 80px;
  width: 100%;
}

.skills-header {
  justify-self: flex-start;
  color: #1f2227;
  font-size: 45px;
  font-weight: 400;
  height: 180px;
}

.skills-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background: linear-gradient(to bottom right, #1f2227 0%, #437aed 100%);
  -webkit-clip-path: polygon(0 45%, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 45%, 100% 0, 100% 100%, 0 100%); /* Slanted top */
  z-index: -1;
}

.skills-grid {
  width: 100%;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 50px;
  gap: 50px;
  max-width: 700px;
}

.icon-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 999999999px;
  width: 75px;
  height: 75px;
  box-shadow: 0px 1px 18px 5px rgba(4, 55, 174, 0.53);
  -webkit-transform: translatey(0px);
          transform: translatey(0px);
  -webkit-animation: float 7s ease-in-out infinite;
          animation: float 7s ease-in-out infinite;
  background: white;
  border: 1px solid white;
  margin: auto;
}

.icon-container svg,
.icon-container img {
  object-fit: contain;
  max-width: 50px;
  max-height: 100%;
  z-index: 99999999999;
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes float {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .skills-grid {
    grid-template-columns: repeat(3, 1fr);
    max-width: 400px;
  }
}

@media (max-width: 400px) {
  .skills-header {
    font-size: 40px;
  }
  .skills-container {
    /* height: 70vh; */
    padding: 40px 20px;
  }

  .skills-container::before {
    height: 90vh;
  }

  .skills-grid {
    grid-gap: 40px;
    gap: 40px;
  }

  .icon-container {
    width: 50px;
    height: 50px;
  }

  .icon-container svg,
  .icon-container img {
    max-width: 30px;
  }
}

