.button {
  font-size: 18px;
  width: 140px;
  height: 50px;
  font-weight: 100;
  border-radius: 40px;
  color: #fff;
  padding: 4px 8px;
  border: none;
  background: #1f2227;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  will-change: transform;
  -webkit-transition: transform 500ms ease-in-out, box-shadow 500ms ease-in-out;
  transition: transform 500ms ease-in-out, box-shadow 500ms ease-in-out;
  transform-origin: center;
  transform: translateZ(0);
}

.button:hover {
  transform: scale(1.1);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  background-position: 100%;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 1px 12px 5px #ccc;
}

@media (max-width: 399px) {
  .button {
    font-size: 14px;
    width: 100px;
    height: 45px;
  }
}
