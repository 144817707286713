.social {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1f2227;
  width: 100%;
  height: 20vh;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.social-links li a {
  border: 3px solid #fff;
  width: 200px;
  height: 200px;
}

.social-icons li {
  list-style: none;
  margin: 0 24px;
}

.social-icons li a {
  color: #fff;
  font-size: 60px;
}

.social-icons li a i:hover {
  background: #437aed;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* MOBILE */
@media (max-width: 812px) {
  .social-icons li {
    margin: 0 12px;
  }

  .social-icons li a {
    color: #fff;
    font-size: 40px;
  }
}
