@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: normal;
}

h1 {
  font-size: 24px;
  font-weight: 300;
}

.btn {
  font-size: 18px;
  width: 140px;
  height: 50px;
  font-weight: 100;
  border-radius: 40px;
  padding: 3px;
  outline: none;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  overflow: hidden;
  background-image: -webkit-linear-gradient(
    30deg,
    transparent 50%,
    #3e72e2 50%
  );
  background-image: linear-gradient(30deg, transparent 50%, #3e72e2 50%);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 600ms ease-in-out;
  transition: background 600ms ease-in-out;
}

.btn:hover {
  background-position: 100%;
  color: #fff;
  cursor: pointer;
}

.btn-dark {
  font-size: 18px;
  width: 140px;
  height: 50px;
  padding: 3px;
  outline: none;
  border: 3px solid #fff;
  background: transparent;
  color: #fff;
  overflow: hidden;
  background-image: -webkit-linear-gradient(30deg, #1f2227 50%, #3e72e2 50%);
  background-image: linear-gradient(30deg, #1f2227 50%, #3e72e2 50%);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 600ms ease-in-out;
  transition: background 600ms ease-in-out;
}

.btn-dark:hover {
  background-position: 100%;
  color: #fff;
  cursor: pointer;
}

.btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 5px;
}

/* MOBILE */
@media (max-width: 812px) {
  .btn {
    font-size: 12px;
    width: 100px;
    height: 35px;
  }
}
