.contact-container {
  height: 60vh;
  width: 100%;
  max-width: 1200px;
  padding: 40px;
  margin: auto;
}
.contact-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  margin: auto;
}
.contact-headers {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.contact-header {
  font-weight: 600;
  color: #1f2227;
  font-size: 45px;
}

.secondary-header {
  color: #437bf5;
}
.linkedin-logo {
  width: 100%;
  height: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
}

/* MOBILE */
@media (max-width: 599px) {
  .contact-header {
    font-size: 35px;
  }
}

@media (max-width: 399px) {
  .contact-container {
    height: 80vh;
    padding: 20px;
  }
  .linkedin-logo {
    height: 35px;
  }
  .contact-header {
    font-size: 28px;
  }
  .buttons-container {
    width: 100%;
    flex-direction: column;
  }

  .buttons-container .button {
    width: 100%;
  }

  .contact-container {
    padding: 20px;
  }
}

@media (max-width: 900px) {
  .contact-content {
    max-width: 500px;
    gap: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
}
