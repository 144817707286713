.container {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  padding: 40px;
  width: 100%;
}

.bold {
  font-weight: 400;
}

.profile-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  z-index: 10;
  max-width: 450px;
  background: transparent;
  color: #1f2227;
}

.about-text {
  text-align: left;
}

.about-content p {
  font-size: 20px;
}

h1 {
  font-size: 30px;
}

.profile-header {
  color: #1f2227;
  font-size: 45px;
  font-weight: 400;
}

.bio-text {
  font-size: 22px;
  font-weight: 350;
  color: #1f2227;
  /* color: #333b47; */
}

.about-content {
  width: 100%;
  max-width: 550px;
}

.bio-image {
  max-width: 100%;
  min-width: 220px;
}

/* MOBILE */
@media (max-width: 1000px) {
  .container {
    height: 100vh;
    justify-content: flex-end;
    flex-direction: column-reverse;
    gap: 30px;
  }
  .profile-content {
    max-width: 510px;
  }
}

@media (max-width: 499px) {
  .profile-header {
    font-size: 35px;
  }
  .container {
    padding: 20px;
    height: 85vh;
  }
}

@media (max-width: 399px) {
  .profile-header {
    font-size: 35px;
  }
  .container {
    padding: 20px;
  }
}

@media (max-width: 399px) and (max-height: 600px) {
  .container {
    height: 110vh;
  }
}
