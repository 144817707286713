.grid-container {
  display: grid;
  align-content: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
}

.gallery-item {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
  width: 500px;
  height: 300px;
}

.gallery-image {
  width: 100%;
  height: 100%;
  transition: transform 400ms ease-out;
  z-index: 50;
}
.gallery-item .btn-dark {
  z-index: 100;
  position: absolute;
  opacity: 0;
  transition: 0.8s ease;
}

.gallery-item:hover .gallery-image {
  opacity: 0.4;
  transition: 0.8s ease;
}

.gallery-item:hover .btn-dark {
  opacity: 1;
}

/* MODAL */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
}
.modal-content h1 {
  margin-bottom: 8px;
}

.modal-content p {
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 20px;
}

.modal-content h1,
.modal-content p {
  text-align: center;
}

.modal-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-btns a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  height: 45px;
  width: 130px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}
.modal-btns button {
  background: transparent;
  outline: none;
  border: none;
  font-size: 20px;
}

.modal-btns button:hover {
  color: #437aed;
  cursor: pointer;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* SMALLER DESTOP */
@media (max-width: 1100px) and (max-height: 900px) {
  .modal {
    max-height: 80%;
  }

  .gallery-item {
    max-width: 280px;
    max-height: 180px;
  }
}
@media (min-width: 1000px) and (max-width: 1400px) {
  .modal-btns a {
    height: 35px;
    width: 120px;
    font-size: 18px;
    z-index: 500;
  }
  .modal-btns button {
    z-index: 500;
  }
  .gallery-item {
    width: 320px;
    height: 200px;
  }
  .modal {
    width: 85%;
    height: 90%;
  }
  .modal-content h1 {
    font-size: 24px;
  }
  .modal-content p {
    font-size: 16px;
  }
}

/* MOBILE */
@media (max-width: 1000px) {
  .modal-btns a {
    font-size: 15px;
    width: 120px;
  }
  .grid-container {
    height: 200vh;
  }
  .gallery {
    grid-template-columns: 1fr;
  }
  .gallery-item {
    width: 300px;
    height: 200px;
  }
  h1 {
    font-weight: 400;
    font-size: 30px;
  }
  .modal {
    width: 75%;
    height: 50%;
  }
}

/* SHORT PHONES */
@media (max-width: 375px) and (max-height: 667px) {
  .grid-container {
    height: 225vh;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  .grid-container {
    height: 250vh;
  }

  .gallery-item {
    width: 275px;
    height: 175px;
  }
}
