.ReactModal__Overlay--after-open {
  z-index: 500;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.7) !important;
}

/* SHOWCASE */
.showcase {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  background: #1f2227;
  background: -webkit-linear-gradient(top left, #1f2227 0%, #437aed 100%);
  background: linear-gradient(to bottom right, #1f2227 0%, #437aed 100%);
}

.showcase-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 20px;
  height: 45px;
  width: 130px;
}

.showcase .showcase-content {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px;
  padding: 8px;
}

.showcase .showcase-content h1 {
  color: #fff;
  font-size: 70px;
  margin: 5px 0;
  font-weight: 300;
}

.showcase .showcase-content h1 span {
  color: #437bf5;
}

.showcase .showcase-content h5 {
  color: #fff;
  font-size: 26px;
  font-weight: 300;
  margin: 8px 0 20px 0;
}

/* MOBILE */
@media (max-width: 812px) {
  .showcase .showcase-content h1 {
    font-size: 40px;
    margin: 5px 0;
  }
  .showcase .showcase-content h5 {
    color: #fff;
    font-size: 20px;
    margin: 8px 0 20px 0;
  }

  .ReactModal__Content,
  .iframe {
    max-height: 430px !important;
  }

  .ReactModal__Content {
    inset: 20px !important;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  .showcase .showcase-content h5 {
    font-size: 18px;
  }
}

/* FLOATING DOTS */
.showcase .dot {
  width: 5px;
  height: 5px;
  background: #437aed;
  border-radius: 50%;
}

.showcase .dotWrapper {
  position: absolute;
}

.showcase .dotWrapper-1 {
  top: 55%;
  left: 80%;
  -webkit-animation: flying 38s ease-in-out -4.9s infinite alternate;
  animation: flying 38s ease-in-out -4.9s infinite alternate;
}
.showcase .dotWrapper-2 {
  top: 17%;
  left: 49%;
  -webkit-animation: flying 35s ease-in-out -2.1s infinite alternate;
  animation: flying 35s ease-in-out -2.1s infinite alternate;
}
.showcase .dotWrapper-3 {
  top: 80%;
  left: 83%;
  -webkit-animation: flying 48s ease-in-out -4s infinite alternate;
  animation: flying 48s ease-in-out -4s infinite alternate;
}
.showcase .dotWrapper-4 {
  top: 20%;
  left: 50%;
  -webkit-animation: flying 65s ease-in-out -8.9s infinite alternate;
  animation: flying 65s ease-in-out -8.9s infinite alternate;
}
.showcase .dotWrapper-5 {
  top: 58%;
  left: 2%;
  -webkit-animation: flying 40s ease-in-out -8.1s infinite alternate;
  animation: flying 40s ease-in-out -8.1s infinite alternate;
}
.showcase .dotWrapper-6 {
  top: 59%;
  left: 56%;
  -webkit-animation: flying 27s ease-in-out -4s infinite alternate;
  animation: flying 27s ease-in-out -4s infinite alternate;
}
.showcase .dotWrapper-7 {
  top: 60%;
  left: 78%;
  -webkit-animation: flying 36s ease-in-out -5.5s infinite alternate;
  animation: flying 36s ease-in-out -5.5s infinite alternate;
}
.showcase .dotWrapper-8 {
  top: 71%;
  left: 59%;
  -webkit-animation: flying 51s ease-in-out -3.8s infinite alternate;
  animation: flying 51s ease-in-out -3.8s infinite alternate;
}
.showcase .dotWrapper-9 {
  top: 65%;
  left: 38%;
  -webkit-animation: flying 55s ease-in-out -4.2s infinite alternate;
  animation: flying 55s ease-in-out -4.2s infinite alternate;
}
.showcase .dotWrapper-10 {
  top: 80%;
  left: 57%;
  -webkit-animation: flying 39s ease-in-out -1.1s infinite alternate;
  animation: flying 39s ease-in-out -1.1s infinite alternate;
}
.showcase .dotWrapper-11 {
  top: 64%;
  left: 66%;
  -webkit-animation: flying 21s ease-in-out -1.4s infinite alternate;
  animation: flying 21s ease-in-out -1.4s infinite alternate;
}
.showcase .dotWrapper-12 {
  top: 71%;
  left: 17%;
  -webkit-animation: flying 42s ease-in-out -3.1s infinite alternate;
  animation: flying 42s ease-in-out -3.1s infinite alternate;
}
.showcase .dotWrapper-13 {
  top: 72%;
  left: 77%;
  -webkit-animation: flying 44s ease-in-out -1.2s infinite alternate;
  animation: flying 44s ease-in-out -1.2s infinite alternate;
}
.showcase .dotWrapper-14 {
  top: 37%;
  left: 31%;
  -webkit-animation: flying 70s ease-in-out -6.7s infinite alternate;
  animation: flying 70s ease-in-out -6.7s infinite alternate;
}
.showcase .dotWrapper-15 {
  top: 57%;
  left: 17%;
  -webkit-animation: flying 62s ease-in-out -6.8s infinite alternate;
  animation: flying 62s ease-in-out -6.8s infinite alternate;
}
.showcase .dotWrapper-16 {
  top: 43%;
  left: 53%;
  -webkit-animation: flying 57s ease-in-out -6.6s infinite alternate;
  animation: flying 57s ease-in-out -6.6s infinite alternate;
}
.showcase .dotWrapper-17 {
  top: 34%;
  left: 45%;
  -webkit-animation: flying 28s ease-in-out -3.9s infinite alternate;
  animation: flying 28s ease-in-out -3.9s infinite alternate;
}
.showcase .dotWrapper-18 {
  top: 15%;
  left: 80%;
  -webkit-animation: flying 38s ease-in-out -7.5s infinite alternate;
  animation: flying 38s ease-in-out -7.5s infinite alternate;
}
.showcase .dotWrapper-19 {
  top: 60%;
  left: 45%;
  -webkit-animation: flying 38s ease-in-out -9.9s infinite alternate;
  animation: flying 38s ease-in-out -9.9s infinite alternate;
}
.showcase .dotWrapper-20 {
  top: 30%;
  left: 20%;
  -webkit-animation: flying 31s ease-in-out -7s infinite alternate;
  animation: flying 31s ease-in-out -7s infinite alternate;
}

@-webkit-keyframes rotating {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  25%,
  75% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  25%,
  75% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: rotate(360deg);
  }
}
@-webkit-keyframes flying {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(20px, 50px);
  }
  20% {
    transform: translate(-30px, 10px);
  }
  30% {
    transform: translate(10px, 60px);
  }
  40% {
    transform: translate(50px, 0px);
  }
  50% {
    transform: translate(-10px, -40px);
  }
  60% {
    transform: translate(-40px, 20px);
  }
  70% {
    transform: translate(30px, -30px);
  }
  80% {
    transform: translate(0px, -60px);
  }
  90% {
    transform: translate(40px, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes flying {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(20px, 50px);
  }
  20% {
    transform: translate(-30px, 10px);
  }
  30% {
    transform: translate(10px, 60px);
  }
  40% {
    transform: translate(50px, 0px);
  }
  50% {
    transform: translate(-10px, -40px);
  }
  60% {
    transform: translate(-40px, 20px);
  }
  70% {
    transform: translate(30px, -30px);
  }
  80% {
    transform: translate(0px, -60px);
  }
  90% {
    transform: translate(40px, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
