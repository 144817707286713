.fade-in {
  opacity: 0;
  transform: translateY(40vh);
  visibility: hidden;
  transition: opacity 1400ms ease-out, transform 600ms ease-out,
    visibility 1400ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in.visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
