.navbar {
  width: 100%;
  height: max-content;
  position: fixed;
  top: 0;
  text-align: center;
  transition: top 0.6s;
  z-index: 50;
  background: #2e5195;
  height: 100px;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  height: 100%;
}

.logo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  list-style: none;
  height: 100%;
}

.nav-links li a {
  font-weight: 500;
  text-decoration: none;
  font-size: 22px;
  padding-bottom: 12px;
}

.dark-links {
  color: #1f2227;
}
.light-links {
  color: #fff;
}

.nav-links li a:hover {
  border-bottom: 4px solid #437aed;
}

/* MOBILE */
@media (max-width: 812px) {
  .navbar {
    height: 80px;
  }
  .logo {
    width: 50px;
    height: 50px;
  }
  .nav-container {
    padding: 10px 20px;
  }

  .nav-links {
    gap: 10px;
  }

  .nav-links li a {
    font-size: 20px;
    padding-bottom: 8px;
  }
}
/* @media (max-width: 499px) {
  .logo {
    width: 30px;
    height: 30px;
  }
  .nav-container {
  }

  .nav-links {
    gap: 10px;
  }

  .nav-links li a {
    font-size: 16px;
  }
} */
